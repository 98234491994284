<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Spacing"
                >
                    <template v-slot:description>
                        <div>
                            Spacing determines the space between elements such as headline, body text, images, margins, containers, etc... In the case of our applications, we use increments of 4px to define spacing. Please use the margin &amp; padding charts below for references.
                        </div>
                        <div class="mt-2">
                            Example: When a design mockup layout or instruction asks for "large" spacing on the left side of something then use the vuetify default CSS class of "ml-4" or "pl-4" to acheive "16px" of spacing on the left side. When it calls for "small" spacing on the left side, then use the vuetify default CSS class of "ml-2" or "pl-2" to achieve "8px" of spacing on the left side. <hb-link color="secondary" href="https://v2.vuetifyjs.com/en/styles/spacing/" target="_blank" class="ml-2">Go to Vuetify's Spacing Documentation</hb-link>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="For Margin: Design System Spacing + Corresponding Vuetify Class To Use + Example Code" class="mt-4 mb-6">
            <div class="mb-6 mx-6 pt-6">
                <hb-data-table
                    :headers="headers"
                    :items="marginItems"
                >
                    <template v-slot:item.name="{ item }">
                        <span class="font-weight-medium">{{ item.name }}</span>
                    </template>
                    <template v-slot:item.visual="{ item }">
                        <div class="d-flex justify-center">
                            <div class="hb-error" :style="'width:' + item.unit + ';height:' + item.unit">&nbsp;</div>
                        </div>
                    </template>
                </hb-data-table>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
                
                <div class="error mb-4" style="border: 1px solid; border-radius:0;">
                    <div class="ma-2 hb-text-night" style="background: #ffffff;">The CSS class "ma-2" Applies 8px of margin to all sides of this div.</div>
                </div>
<pre>
&lt;div class="ma-2">The CSS class "ma-2" Applies 8px of margin to all sides of this div.&lt;/div>
</pre>
            </hb-card>
            
        </hb-card>

        <hb-card title="For Padding: Design System Spacing + Corresponding Vuetify Class To Use + Example Code" class="mt-4 mb-6">
            <div class="mb-6 mx-6 pt-6">
                <hb-data-table
                    :headers="headers"
                    :items="paddingItems"
                >
                    <template v-slot:item.name="{ item }">
                        <span class="font-weight-medium">{{ item.name }}</span>
                    </template>
                    <template v-slot:item.visual="{ item }">
                        <div class="d-flex justify-center">
                            <div class="hb-error" :style="'width:' + item.unit + ';height:' + item.unit">&nbsp;</div>
                        </div>
                    </template>
                </hb-data-table>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>

                <div class="error mb-4" style="border-radius:0;">
                    <div class="mx-4 hb-text-night" style="background: #ffffff;">
                        The CSS class "px-4" Applies 16px of padding to the left and right sides of this div.
                    </div>
                </div>
<pre>
&lt;div class="px-4">The CSS class "px-4" Applies 16px of padding to the left and right sides of this div.&lt;/div>
</pre>
            </hb-card>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemSpacing",
        data: function() {
            return {
                headers: [
                    { text: 'Design System Name', value: 'name' },
                    { text: 'Spacing Unit', value: 'unit' },
                    { text: 'Visual Reference', value: 'visual' },
                    { text: 'CSS Class: All Sides', value: 'a' },
                    { text: 'CSS Class: Left & Right', value: 'x' },
                    { text: 'CSS Class: Top & Bottom', value: 'y' },
                    { text: 'CSS Class: Left', value: 'l' },
                    { text: 'CSS Class: Right', value: 'r' },
                    { text: 'CSS Class: Top', value: 't' },
                    { text: 'CSS Class: Bottom', value: 'b' },
                ],
                marginItems: [
                    { name: 'x-small', unit: '4px', visual: '', a: 'ma-1', x: 'mx-1', y: 'my-1', l: 'ml-1', r: 'mr-1', t: 'mt-1', b: 'mb-1' },
                    { name: 'small', unit: '8px', visual: '', a: 'ma-2', x: 'mx-2', y: 'my-2', l: 'ml-2', r: 'mr-2', t: 'mt-2', b: 'mb-2' },
                    { name: 'medium', unit: '12px', visual: '', a: 'ma-3', x: 'mx-3', y: 'my-3', l: 'ml-3', r: 'mr-3', t: 'mt-3', b: 'mb-3' },
                    { name: 'large', unit: '16px', visual: '', a: 'ma-4', x: 'mx-4', y: 'my-4', l: 'ml-4', r: 'mr-4', t: 'mt-4', b: 'mb-4' },
                    { name: 'x-large', unit: '20px', visual: '', a: 'ma-5', x: 'mx-5', y: 'my-5', l: 'ml-5', r: 'mr-5', t: 'mt-5', b: 'mb-5' },
                    { name: 'xx-large', unit: '24px', visual: '', a: 'ma-6', x: 'mx-6', y: 'my-6', l: 'ml-6', r: 'mr-6', t: 'mt-6', b: 'mb-6' },
                ],
                paddingItems: [
                    { name: 'x-small', unit: '4px', visual: '', a: 'pa-1', x: 'px-1', y: 'py-1', l: 'pl-1', r: 'pr-1', t: 'pt-1', b: 'pb-1' },
                    { name: 'small', unit: '8px', visual: '', a: 'pa-2', x: 'px-2', y: 'py-2', l: 'pl-2', r: 'pr-2', t: 'pt-2', b: 'pb-2' },
                    { name: 'medium', unit: '12px', visual: '', a: 'pa-3', x: 'px-3', y: 'py-3', l: 'pl-3', r: 'pr-3', t: 'pt-3', b: 'pb-3' },
                    { name: 'large', unit: '16px', visual: '', a: 'pa-4', x: 'px-4', y: 'py-4', l: 'pl-4', r: 'pr-4', t: 'pt-4', b: 'pb-4' },
                    { name: 'x-large', unit: '20px', visual: '', a: 'pa-5', x: 'px-5', y: 'py-5', l: 'pl-5', r: 'pr-5', t: 'pt-5', b: 'pb-5' },
                    { name: 'xx-large', unit: '24px', visual: '', a: 'pa-6', x: 'px-6', y: 'py-6', l: 'pl-6', r: 'pr-6', t: 'pt-6', b: 'pb-6' },
                ],
            };
        },
    }
</script>

<style scoped>

</style>
